import React from "react";
import "./PlayerDetails.css";
import "./responsive.css";
import "./lightslider.css";
import { PLAYER_ICON_DEFAULT} from '../../common/staticData';
import { format } from "date-fns"
import GameDetails  from "./GameDetails";
const PlayerDetails = ({ player ,gameList ,onMenuChange ,playVideo}) => {
  const dob =new Date(player?.player_date_of_birth)
  const gamesPlayed =gameList.length
  const jersey_style ={color: '#3794c1 !important'}

  const getFootImage = (foot) => {
    switch (foot) {
      case 'Both':
        return '../../../both-shoes.png';
      case 'Right':
        return '../../../RightFoot.png';
      case 'Left':
        return '../../../LeftFoot.png';
      default:
        return '../../../NoFoot.png';
    }
  }
  const onMenuUpdate=(data)=>{
    onMenuChange('game_stats',data)
  }
  const playVideoClick=(data)=>{
    playVideo(data)
  }
  return (
    <div className="bodyBackground">
    <section className="player-details-outer">
      <div className="container">
        <div className="player-info-row">
          <div className="player-info-col">
            <div className="skill-info">
              <img src={player?.team_image? player?.team_image.includes(process.env.REACT_APP_S3_URI) ? player?.team_image : process.env.REACT_APP_S3_URI + player?.team_image : '../../../ball-img.png'} alt=""/>
            </div>
            <div className="jerssy_numberOuter">
              <img src={getFootImage(player?.player_strong_foot)} alt="" style={{ width: "40%" }} />
              <h3>{player?.player_strong_foot ? 'Preferred Foot: ' + player?.player_strong_foot : 'No Preferred Foot'}</h3>
            </div>
            <div className="jerssy_numberOuter">
              <div className="js-col">
                <span className="jerssy_col">{player?.player_height ? player?.player_height + ' cm' : ''}</span>
                <h3>Height</h3>
              </div>
              <div className="js-col">
                <span className="jerssy_col">{player?.player_weight ? player?.player_weight + ' Kg' : ''}</span>
                <h3>Weight</h3>
              </div>
            </div>
          </div>
          <div className="player-profile-img">
            <h3>{player?.player_full_name}</h3>
            <img src={player?.player_image ? player?.player_image.includes(process.env.REACT_APP_S3_URI) ? player?.player_image : process.env.REACT_APP_S3_URI + player?.player_image : PLAYER_ICON_DEFAULT} alt="" />
          </div>
          <div className="player-info-col">
            <div className="skill-info skill-infoSec">
              <img src="../../../white-jersy-img.png" alt="" />
              <span style={jersey_style}>{player?.player_jersey_number < 99?player.player_jersey_number:''}</span>
            </div>
            <div className="jerssy_numberOuter">
              <span className="jerssy_col">{format(dob, "dd")}</span>
              <span className="jerssy_col">{format(dob, "MM")}</span>
              <span className="jerssy_col">{format(dob, "yyyy")}</span>
              <h3>Birthday</h3>
            </div>
            <div className="faul-row jerssy_numberOuter">
              <div className="faul-col">
                <img src="../../../foul-img-02.png" alt="" />
                <span>{player?.player_total_red_cards??'0'}</span>
              </div>
              <div className="faul-col">
                <img src="../../../foul-img-01.png" alt="" />
                <span>{player?.player_total_yelow_cards??'0'}</span>
              </div>
              <h3>{player?.player_total_red_cards || player?.player_total_yellow_cards ? 'Cards' : 'No Cards'}</h3>
             </div>
          </div>
        </div>
        <div className="team-info team-info-sec">
          <span>Age Group: {player?.player_age_group_name?? ' NA'}</span>
          <span>Position: {player?.player_position_name?? ' NA'}</span>
          <span>Games Played: {gamesPlayed}</span>
        </div>
        <div className="seeMoreEffect" style={{ marginTop: "20px" }}>
          <GameDetails gameList={gameList} onMenuUpdate={onMenuUpdate} playVideoClick={playVideoClick} />
        </div>
      </div>
    </section>
    </div>
  );
};


export default PlayerDetails;