import React, { useReducer, useEffect, useState } from 'react';
import { Box, CircularProgress, Divider, MenuItem, Popover, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel,    Button,
    Snackbar, IconButton } from '@mui/material';
import { useSelector } from 'react-redux';

import EditIcon from '@mui/icons-material/EditOutlined';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreOutlined';

import GameService from '../../../services/game.service';
import { PLAYER_ICON_DEFAULT } from '../../../common/staticData';
import { getComparator, stableSort } from '../components/utilities';
import { ActionData, MenuProps } from '../components/common';
import { getPeriod } from '../games/tabs/overview/tagListItem';
import GameExportToEdits from '../games/tabs/overview/exportEdits';
import PlayerEditDialog from '../players/playerEditDialog';
import GoalkeepersGamesDialog from './gamesDialog';
import PlayerStatDialog from '../players/status';
import SeasonContext from '../../../common/Context';
import { getFilteredArray } from '../../../helpers/coach';
import TeamStatsVideoPlayer from '../teams/tabs/stats/videoDialog';
import ShareIcon from '@mui/icons-material/Share';
import CryptoJS from 'crypto-js';
import CloseIcon from '@mui/icons-material/Close';

const headCells = [
    { id: 'total_player_games', title: 'Games', action: '' },
    { id: 'total_build_ups', title: 'Build Ups', action: 'BuildUp' },
    { id: 'total_short_passes', title: 'Short Passes', action: 'ShortPass' },
    { id: 'total_long_passes', title: 'Long Passes', action: 'LongPass' },
    { id: 'total_goal_received', title: 'Goals Received', action: 'GoalReceived' },
    { id: 'total_super_save', title: 'Super Saved', action: 'SuperSaved' },
    { id: 'total_saved', title: 'Saved', action: 'Saved' },
    { id: 'total_one_vs_one', title: '1 vs 1', action: 'One' },
    { id: 'total_air_challenge', title: 'Air Challenges', action: 'AirChallenge' },
    { id: 'total_ground_challenge', title: 'Ground Challenges', action: 'GroundChallenge' },
    { id: 'total_goalkeeper_exit', title: 'Box Exits', action: 'Exits' },
    { id: 'total_opponent_crosses', title: 'Opponents Crosses', action: 'Cross' },
    { id: 'total_opponent_corners', title: 'Opponents Corners', action: 'Corner' },
    { id: 'total_opponent_free_kicks', title: 'Opponents Free Kicks', action: 'FreeKick' }
];

const Goalkeepers = ({ t }) => {
    const { seasonFilter } = React.useContext(SeasonContext);
    const [state, setState] = useReducer((old, action) => ({ ...old, ...action }), {
        hoverIndex: -1,
        loading: false,
        teamFilter: 'none'
    });

    const { teamFilter } = state;

    const teamsList = useSelector((state) => state.coach.teamsList);
    const gamesList = useSelector((state) => state.coach.gamesList);
    const goalkeepersStatsAdvanceSummaryList = useSelector((state) => state.coach.goalkeepersStatsAdvanceSummaryList);

    const [goalkeeperList, setGoalkeeperList] = useState([]);
    const [filteredGoalkeepers, setFilteredGoalkeepers] = useState([]);
    const [seasonTeamsList, setSeasonTeamsList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('total_player_games');
    const [playerStat, setPlayerStat] = useState(null);
    const [editOpen, setEditOpen] = useState(false);
    const [gameList, setGameList] = useState([]);
    const [gameIdsForStats, setGameIdsForStats] = useState([]);
    const [statOpen, setStatOpen] = useState(false);
    const [playData, setPlayData] = useState([]);
    const [exportOpen, setExportOpen] = useState(false);
    const [playerGames, setPlayerGames] = useState([]);
    const [gamesOpen, setGamesOpen] = useState(false);
    const [teamId, setTeamId] = useState(null);
    const [videoOpen, setVideoOpen] = useState(false);

    const [currentGoalKeeper, setCurrentGoalKeeper] = useState(null);
    const [showResultAlert, setShowResultAlert] = useState(false);

    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const menuPopoverOpen = Boolean(menuAnchorEl);
    const menuPopoverId = menuPopoverOpen ? 'simple-popover' : undefined;

    const { user: currentUser } = useSelector((state) => state.auth);

    const handleChange = (prop) => (e) => {
        setState({ ...state, [prop]: e.target.value });
    };

    const handleRequestSort = (prop) => {
        const isAsc = orderBy === prop && order === 'desc';

        setOrder(isAsc ? 'asc' : 'desc');
        setOrderBy(prop);
    };

    const handleShowMenu = (player) => (e) => {
        setPlayerStat(player);

        if (player !== null && Object.getOwnPropertyNames(player).length !== 0) {
            GameService.getPlayerById(player.player_id).then((res) => {
                setCurrentGoalKeeper({
                    ...currentGoalKeeper,
                    ...res,
                    player_full_name: res.player_first_name.trim() + ' ' + res.player_last_name.trim(),
                    player_jersey_number: res.player_jersey_number,
                    player_image: res.player_image,
                    player_id: res.player_id,
                    player_position_id: res.player_position_id,
                    player_position_name: res.player_position_name,
                    player_first_name: res.player_first_name,
                    player_last_name: res.player_last_name,
                    player_date_of_birth: res.player_date_of_birth,
                    player_weight: res.player_weight,
                    player_height: res.player_height,
                    player_age_group_id: res.player_age_group_id,
                    player_age_group_name: res.player_age_group_name
                });
            });
        }

        setMenuAnchorEl(e.currentTarget);
    };

    const handleDisplayStats = async () => {
        setMenuAnchorEl(null);

        const goalkeeper_games = gamesList.filter(
            (game) => game.season_id === playerStat.season_id && (game.home_team_id === playerStat.team_id || game.away_team_id === playerStat.team_id) && game.video_url.toLowerCase() !== 'no video'
        );
        setGameList(goalkeeper_games);
        await GameService.getPlayersGames(seasonFilter.id, teamId, playerStat.player_id).then((res) => {
            const gameIds = res.map((item) => item.game_id);
            setGameIdsForStats(gameIds);
            setStatOpen(true);
        });
    };

    const handleDisplayVideo = (cell, player) => async (e) => {
        if (cell.action !== '' && player[cell.id] !== undefined && player[cell.id] > 0) {
            let playerGameIds = [];
            const goalkeeper_games = gamesList.filter(
                (game) => game.season_id === player.season_id && (game.home_team_id === player.team_id || game.away_team_id === player.team_id) && game.video_url.toLowerCase() !== 'no video'
            );
            setGameList(goalkeeper_games);
            await GameService.getPlayersGames(seasonFilter.id, teamId, player.player_id).then((res) => {
                playerGameIds = res.map((item) => item.game_id);
            });

            if (cell.title.includes('Opponents')) {
                await GameService.getOpponentTags(
                    currentUser.id,
                    player.team_id,
                    null,
                    playerGameIds.length === 0 ? null : playerGameIds.join(','),
                    ActionData[cell.action].action_id,
                    ActionData[cell.action].action_type_id,
                    ActionData[cell.action].action_result_id,
                    null,
                    null,
                    null,
                    null,
                    null
                ).then((res) => {
                    setPlayData(
                        res.map((item) => {
                            return {
                                tag_id: item.id,
                                start_time: item.player_tag_start_time,
                                end_time: item.player_tag_end_time,
                                player_name: item.player_names,
                                action_name: item.action_names,
                                action_type: item.action_type_names,
                                action_result: item.action_result_names,
                                game_id: item.game_id,
                                team_id: player.team_id,
                                court_area: item.court_area_id,
                                inside_pain: item.inside_the_pain,
                                period: getPeriod(item.period),
                                time: item.time_in_game,
                                home_team_image: item.home_team_logo,
                                away_team_image: item.away_team_logo,
                                home_team_goals: item.home_team_goal,
                                away_team_goals: item.away_team_goal
                            };
                        })
                    );

                    setVideoOpen(true);
                });
            } else {
                await GameService.getGamePlayerTags(
                    currentUser.id,
                    player.team_id,
                    `${player.player_id}`,
                    playerGameIds.length === 0 ? null : playerGameIds.join(','),
                    ActionData[cell.action].action_id,
                    ActionData[cell.action].action_type_id,
                    ActionData[cell.action].action_result_id,
                    null,
                    null,
                    null,
                    null,
                    null
                ).then((res) => {
                    const flist = cell.title === 'Exits' ? res.filter((item) => item.inside_the_pain === false) : res;

                    setPlayData(
                        flist.map((item) => {
                            return {
                                tag_id: item.id,
                                start_time: item.player_tag_start_time,
                                end_time: item.player_tag_end_time,
                                player_name: item.player_names,
                                action_name: item.action_names,
                                action_type: item.action_type_names,
                                action_result: item.action_result_names,
                                game_id: item.game_id,
                                team_id: player.team_id,
                                court_area: item.court_area_id,
                                inside_pain: item.inside_the_pain,
                                period: getPeriod(item.period),
                                time: item.time_in_game,
                                home_team_image: item.home_team_logo,
                                away_team_image: item.away_team_logo,
                                home_team_goals: item.home_team_goal,
                                away_team_goals: item.away_team_goal
                            };
                        })
                    );

                    setVideoOpen(true);
                });
            }
        }
    };

    const handleExportPlayerTags = (cell, player) => async (e) => {
        e.preventDefault();

        if (cell.action !== '' && player[cell.id] !== undefined && player[cell.id] > 0) {
            let playerGameIds = [];

            await GameService.getPlayersGames(seasonFilter.id, teamId, player.player_id).then((res) => {
                playerGameIds = res.map((item) => item.game_id);
            });

            if (cell.title.includes('Opponents')) {
                await GameService.getOpponentTags(
                    currentUser.id,
                    player.team_id,
                    null,
                    playerGameIds.length === 0 ? null : playerGameIds.join(','),
                    ActionData[cell.action].action_id,
                    ActionData[cell.action].action_type_id,
                    ActionData[cell.action].action_result_id,
                    null,
                    null,
                    null,
                    null,
                    null
                ).then((res) => {
                    setPlayData(res);

                    if (res.length > 0) setExportOpen(true);
                });
            } else {
                await GameService.getGamePlayerTags(
                    currentUser.id,
                    player.team_id,
                    `${player.player_id}`,
                    playerGameIds.length === 0 ? null : playerGameIds.join(','),
                    ActionData[cell.action].action_id,
                    ActionData[cell.action].action_type_id,
                    ActionData[cell.action].action_result_id,
                    null,
                    null,
                    null,
                    null,
                    null
                ).then((res) => {
                    const flist = cell.title === 'Exits' ? res.filter((item) => item.inside_the_pain === false) : res;

                    setPlayData(flist);

                    if (flist.length > 0) setExportOpen(true);
                });
            }
        }
    };

    const handleDisplayGames = async () => {
        let gameIds = [];

        setMenuAnchorEl(null);
        await GameService.getPlayersGames(seasonFilter.id, teamId, playerStat.player_id).then((res) => {
            gameIds = res.map((item) => item.game_id);
        });
        await GameService.getGoalkeepersStatsGamebyGame({
            seasonId: playerStat.season_id,
            leagueId: null,
            gameId: gameIds.length === 0 ? null : gameIds.join(','),
            teamId: playerStat.team_id,
            playerId: playerStat.player_id,
            gameTime: null,
            courtAreaId: null,
            insidePaint: null,
            homeAway: null,
            gameResult: null
        }).then((res) => {
            setPlayerGames(stableSort(res, getComparator('desc', 'game_date')));
            setGamesOpen(true);
        });
    };

    const getDisplayName = (player) => {
        if (player) {
            if (player.player_jersey_number === 999) return player.player_position_name;

            return `#${player.player_jersey_number} ${player.player_position_name}`;
        }

        return '-';
    };

    useEffect(() => {
        (async () => {
            setTeamId(teamFilter === 'none' ? null : teamFilter.team_id);
        })();
    }, [teamFilter]);

    const getSortedArray = () => {
        return stableSort(goalkeeperList, getComparator(order, orderBy));
    };

    useEffect(() => {
        (async () => {
            if (
                goalkeepersStatsAdvanceSummaryList &&
                goalkeepersStatsAdvanceSummaryList.hasOwnProperty(seasonFilter === 'none' ? 'all' : seasonFilter.name) &&
                goalkeepersStatsAdvanceSummaryList[seasonFilter === 'none' ? 'all' : seasonFilter.name].length
            )
                setLoading(false);
        })();
    }, [filteredGoalkeepers]);

    useEffect(() => {
        (async () => {
            const sortedPlayers = getSortedArray();
            setFilteredGoalkeepers(sortedPlayers);
        })();
    }, [goalkeeperList]);

    useEffect(() => {
        (async () => {
            setLoading(true);
            if (goalkeepersStatsAdvanceSummaryList && goalkeepersStatsAdvanceSummaryList.hasOwnProperty(seasonFilter === 'none' ? 'all' : seasonFilter.name)) {
                const filtered_arr = getFilteredArray(goalkeepersStatsAdvanceSummaryList[seasonFilter === 'none' ? 'all' : seasonFilter.name], seasonFilter, null, null, teamFilter, null);
                setGoalkeeperList(filtered_arr);
            }
        })();
    }, [goalkeepersStatsAdvanceSummaryList, teamFilter, seasonFilter]);

    useEffect(() => {
        (async () => {
            setLoading(true);
            if (teamsList.length) {
                const filteredTeams = teamsList.filter((team) => team.season_id === seasonFilter.id);
                setSeasonTeamsList(filteredTeams);
                setState({ teamFilter: 'none' });
            }
        })();
    }, [teamsList, seasonFilter]);

    const encryptId = (id) => {
        const secretKey = 'scouting4u';
        const encrypted = CryptoJS.AES.encrypt(id.toString(), secretKey).toString();
        const encoded = encodeURIComponent(encrypted); // Encode encrypted data for URL safety
        return encoded;
    };


    const handleCopyLink = () => {
        navigator.clipboard
            .writeText(`${window.location.origin}/player/${encryptId(playerStat.player_id)}`)
            .then(() => {
                setShowResultAlert(true);
            })
            .catch((err) => {
                console.error('Failed to copy: ', err);
            });
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setShowResultAlert(false);
    };

    const action = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={handleClose}>
                UNDO
            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );


    return (
        <Box sx={{ width: '98%', margin: '0 auto', height: '85vh' }}>
            <Box sx={{ padding: '24px 24px 24px 24px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <p className="page-title">{t('All Goalkeepers')}</p>
                <div style={{ display: 'flex', alignItems: 'center', gap: '24px' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <p className="normal-text">{t('Team')}</p>
                        <Select
                            value={teamFilter}
                            onChange={handleChange('teamFilter')}
                            label=""
                            variant="outlined"
                            IconComponent={ExpandMoreIcon}
                            inputProps={{ 'aria-label': 'Without label' }}
                            MenuProps={MenuProps}
                            sx={{ outline: 'none', height: '36px', width: '300px', fontSize: '0.8rem', '& legend': { display: 'none' }, '& fieldset': { top: 0 } }}
                        >
                            <MenuItem key="0" value="none">
                                {t('All')}
                            </MenuItem>
                            {seasonTeamsList.map((team, index) => (
                                <MenuItem key={index + 1} value={team}>
                                    {team.team_name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                </div>
            </Box>
            {loading && (
                <div style={{ width: '100%', height: '100%', zIndex: 9999, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                </div>
            )}
            <Snackbar
                open={showResultAlert}
                autoHideDuration={3000}
                onClose={handleClose}
                message="Link copied to clipboard!"
                action={action}
            />

            {!loading && goalkeeperList.length > 0 && (
                <Box sx={{ minHeight: '80vh', marginLeft: '10px', background: '#ffffff' }}>
                    <TableContainer sx={{ maxHeight: '85vh' }}>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow height="36px">
                                    <TableCell key="name" align="center" colSpan={2}>
                                        {t('Name')}
                                    </TableCell>
                                    <TableCell key="team" align="center">
                                        {t('Team')}
                                    </TableCell>
                                    {headCells.map((cell) => (
                                        <TableCell key={cell.id} align="center" sortDirection={orderBy === cell.id ? order : false}>
                                            <TableSortLabel active={orderBy === cell.id} direction={orderBy === cell.id ? order : 'asc'} onClick={() => handleRequestSort(cell.id)}>
                                                {t(cell.title)}
                                            </TableSortLabel>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredGoalkeepers.map((player, index) => (
                                    <TableRow key={`${player.player_id}-${index}`} height="70px" hover>
                                        <TableCell key={`${player.player_id}-${index}-0`} width="5%" align="center" sx={{ cursor: 'pointer' }} onClick={handleShowMenu(player)}>
                                            <img
                                                style={{ height: '70px', borderRadius: '8px', paddingTop: '2px', paddingBottom: '2px' }}
                                                src={
                                                    player.player_image
                                                        ? player.player_image.includes(process.env.REACT_APP_S3_URI)
                                                            ? player.player_image
                                                            : process.env.REACT_APP_S3_URI + player.player_image
                                                        : PLAYER_ICON_DEFAULT
                                                }
                                            />
                                        </TableCell>
                                        <TableCell key={`${player.player_id}-${index}-1`} sx={{ width: '115px' }}>
                                            <Box sx={{ paddingLeft: '10px', cursor: 'pointer' }} onClick={handleShowMenu(player)}>
                                                <p className="normal-text">{player?.player_full_name ?? '-'}</p>
                                                <p className="normal-text">{getDisplayName(player)}</p>
                                            </Box>
                                        </TableCell>
                                        <TableCell key={`${player.player_id}-${index}-2`} sx={{ width: '160px' }} align="center">
                                            <p className="normal-text"> {player?.team_name ?? '-'}</p>
                                        </TableCell>
                                        {headCells.map((cell, cId) => (
                                            <TableCell
                                                key={`${cell.id}-${index}-${cId}`}
                                                align="center"
                                                sx={{ cursor: 'pointer', width: '55px' }}
                                                onClick={handleDisplayVideo(cell, player)}
                                                onContextMenu={handleExportPlayerTags(cell, player)}
                                            >
                                                <p className="normal-text"> {player[cell.id] ?? '-'}</p>
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TeamStatsVideoPlayer t={t} open={videoOpen} onClose={() => setVideoOpen(false)} video_url={gameList} tagList={playData} />
                    <PlayerEditDialog t={t} open={editOpen} onClose={() => setEditOpen(false)} player={currentGoalKeeper} page="players" />
                    <PlayerStatDialog
                        key={gameIdsForStats.length}
                        t={t}
                        open={statOpen}
                        onClose={() => setStatOpen(false)}
                        player={playerStat}
                        games={gameList}
                        gameIds={gameIdsForStats}
                        initialState={playerStat}
                    />
                    <GameExportToEdits t={t} open={exportOpen} onClose={() => setExportOpen(false)} tagList={playData} isTeams={false} />
                    <GoalkeepersGamesDialog
                        t={t}
                        open={gamesOpen}
                        onClose={() => setGamesOpen(false)}
                        list={playerGames}
                        playerName={playerStat?.player_full_name ?? ''}
                        teamId={playerStat?.team_id ?? 0}
                    />
                    <Popover
                        id={menuPopoverId}
                        open={menuPopoverOpen}
                        anchorEl={menuAnchorEl}
                        onClose={() => setMenuAnchorEl(null)}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                        sx={{ '& .MuiPopover-paper': { width: '220px', borderRadius: '12px', border: '1px solid #E8E8E8' } }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', padding: '12px 20px', cursor: 'pointer' }} onClick={() => handleDisplayStats()}>
                            <QueryStatsIcon />
                            <p className="menu-item">{t('Accumulated Stats')}</p>
                        </Box>
                        <Divider sx={{ width: '100%' }} />
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', padding: '12px 20px', cursor: 'pointer' }} onClick={() => handleDisplayGames()}>
                            <SportsSoccerIcon />
                            <p className="menu-item">{t('Game By Game')}</p>
                        </Box>
                        <Divider sx={{ width: '100%' }} />
                        <Box
                            sx={{ display: 'flex', alignItems: 'center', gap: '8px', padding: '12px 20px', cursor: 'pointer' }}
                            onClick={() => {
                                setMenuAnchorEl(null);
                                setEditOpen(true);
                            }}
                        >
                            <EditIcon />
                            <p className="menu-item">{t('Edit Player')}</p>
                        </Box>
                        <Divider sx={{ width: '100%' }} />
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px', padding: '12px 20px', cursor: 'pointer' }} onClick={() => handleCopyLink()}>
                            <ShareIcon />
                            <p className="menu-item">{t('Share')}</p>
                        </Box>
                    </Popover>
                </Box>
            )}
        </Box>
    );
};

export default Goalkeepers;
