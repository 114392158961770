import * as React from 'react';
import { useSelector } from 'react-redux';
import { useHotkeys } from 'react-hotkeys-hook';
import { styled } from '@mui/system';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Drawer from '@mui/material/Drawer';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import CssBaseline from '@mui/material/CssBaseline';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import IconButton from '@mui/material/IconButton';

import TextField from '@mui/material/TextField';
import { useNavigate, useParams } from 'react-router-dom';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import ReactPlayer from 'react-player';
import GameService from '../../services/game.service';
import IndividualTagTable from './IndividualTagTable';
import TeamTagTable from './TeamTagTable';
import { Button } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import RefreshIcon from '@mui/icons-material/Refresh';
import { toHHMMSS, toSecond, getUser, setUser, subSecToHHMMSS } from '../../common/utilities';
import Shot from './contents/Shot';
import ShortPass from './contents/ShortPass';
import Pass from './contents/Pass';
import Cross from './contents/Cross';
import Foul from './contents/Foul';
import Dribble from './contents/Dribble';
import SelectMainPlayers from './contents/SelectMainPlayers';
import Others from './contents/Others';
import GK from './contents/GK';
import { getPeriod } from '../newcoach/games/tabs/overview/tagListItem';
import hideImg from '../../assets/icons/bx-hide.png';
import showImg from '../../assets/icons/bx-show.png';
import useVideoPlayer from './common/useVideoPlayer';
import LabelView from './labelView';
import LabelViewCircle from './labelViewCircle';
import UpdatePlayerDetection from './contents/UpdatePlayersDetection';
import gameService from '../../services/game.service';

// import video from "../../assets/play23.mp4";

// const videosrc = 'https://player.vimeo.com/progressive_redirect/playback/765274099/rendition/1080p/file.mp4?loc=external&signature=35f8d5c89d04f0d457c2f08a58d32b7271187bd6a39049053ebeb502924721e0';

const drawerWidth = '30%';

const PLAYBACK_RATE = [
    { rate: 0.3, label: 'x 0.3' },
    { rate: 0.5, label: 'x 0.5' },
    { rate: 1, label: 'x 1' },
    { rate: 1.5, label: 'x 1.5' },
    { rate: 2, label: 'x 2' },
    { rate: 2.5, label: 'x 2.5' },
    { rate: 3, label: 'x 3' }
];

const ControlButton = styled(({ color, ...otherProps }) => <Button {...otherProps} variant="outlined" />)`
    color: ${(props) => props.color};
    margin: 4px;
    text-transform: none;
`;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    flexGrow: 1,
    display: 'block',
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    marginLeft: `-${drawerWidth}`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0
    })
}));

const style = {
    position: 'absolute',
    top: '45%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    p: 4
};
const TagButton = styled(({ color, ...otherProps }) => <Button {...otherProps} variant="outlined" />)`
    color: ${(props) => props.color};
    width: 100%;
`;
let ALL_ACTION_RESULTS = [];

const TAGGING = {
    short_pass: { id: 2, hotkey: 's', value: 'Short Pass' },
    pass: { id: 2, hotkey: 'w', value: 'Pass' },
    shot: { id: 1, hotkey: 'a', value: 'Shot' },
    cross: { id: 3, hotkey: 'c', value: 'Cross' },
    dribble: { id: 7, hotkey: 'q', value: 'Dribble' },
    foul: { id: 8, hotkey: 'e', value: 'Foul' }
};
const HOTKEY_OPTION = { enableOnContentEditable: true };

export default function Tagging() {
    const navigate = useNavigate();
    const { id } = useParams();
    const game_id = Number(atob(id).slice(3, -3));
    const { user: currentUser } = useSelector((state) => state.auth);

    React.useEffect(() => {
        (async () => {
            if (!currentUser) {
                navigate('/');
                window.alert('You must log in first.');

                return;
            }

            await GameService.getGame(game_id)
                .then((res) => {
                    if (!((!res.done_tagging && currentUser.roles.includes('ROLE_TAGGER')) || currentUser.roles.includes('ROLE_ADMIN'))) {
                        navigate('/');
                        window.alert('Game has been already tagged');
                    }
                })
                .catch((e) => {
                    navigate('/');
                });
        })();
    }, [game_id, currentUser, navigate]);

    const player = React.useRef(null);

    const seekTo = (sec) => player.current.seekTo(player.current.getCurrentTime() + sec);

    const [open, setOpen] = React.useState(true);
    const [modalOpen, setModalOpen] = React.useState(false);
    const [modalContent, setModalContent] = React.useState('');
    const [count, setCount] = React.useState(0);

    const [teamTagList, setTeamTagList] = React.useState([]);

    const [playerTagList, setPlayerTagList] = React.useState([]);
    const [tagCnt, setTagCnt] = React.useState(0);
    const [temp_playerTag_list, setTempPlayerTagList] = React.useState([]);
    const [play, setPlay] = React.useState(false);
    const [playRate, setPlayRate] = React.useState(2);
    const [clicked, setClicked] = React.useState(false);
    const [curTeamTag, setCurTeamTag] = React.useState(null);
    const [, setCurTagStatusText] = React.useState('');
    const [defenseTeamGoalKeeper, setDefenseTeamGoalKeeper] = React.useState([]);
    const [teamTagId, setTeamTagId] = React.useState(null);
    const [startTime, setStartTime] = React.useState(null);
    const [teamTagClicked, setTeamTagClicked] = React.useState(true);
    const [isShow, setShow] = React.useState(true);
    const [labelType] = React.useState('first');

    const overlayElRef = React.useRef(null);

    const [width, setWidth] = React.useState(0);
    const [height, setHeight] = React.useState(0);

    const [playerUniqueId, setPlayerUniqueId] = React.useState(0);

    const [timeInt, setTimeInt] = React.useState('3');
    // const [labelshow, setLabelshow] = React.useState("1")

    const [hideLabel, setHideLabel] = React.useState({});
    // const hideLabel = {}

    const setLabelshow = (labelshow) => {
        if (labelshow === '1') {
            setHideLabel((prevHideLabel) => ({ ...prevHideLabel, [playerUniqueId]: 1 })); // Use spread operator to copy existing keys and values in hideLabel, and then add the new key-value pair
        } else {
            setHideLabel((prevHideLabel) => ({ ...prevHideLabel, [playerUniqueId]: 0 }));
        }
    };

    React.useEffect(() => {
        setWidth(overlayElRef.current.clientWidth);
        setHeight(overlayElRef.current.clientHeight);
    }, []);

    function fnShow() {
        setShow(!isShow);
    }

    const { labeldata, videoCufr } = useVideoPlayer(player, game_id);
    const [isSelected, setIsSelected] = React.useState(false);
    const newlabeldata = React.useRef({});

    const [state, setState] = React.useReducer((old, action) => ({ ...old, ...action }), {
        url: '',
        offense: 'home',
        first_second: 'first',
        start_time: '00:00:00',
        home_team_name: '',
        away_team_name: '',
        homePlayers: [],
        awayPlayers: [],
        curTeamTagId: 0,
        game: []
    });

    const updateHomePlayers = (updatedHomePlayers) => {
        setState({
            homePlayers: updatedHomePlayers
        });
    };

    const updateAwayPlayers = (updatedAwayPlayers) => {
        setState({
            awayPlayers: updatedAwayPlayers
        });
    };

    const [config, setConfig] = React.useReducer((old, action) => ({ ...old, ...action }), {
        sec_before: 12,
        sec_after: 6
    });

    const [teamTag, setTeamTag] = React.useReducer((old, action) => ({ ...old, ...action }), {
        id: 0,
        game_id,
        offensive_team_id: 0,
        defensive_team_id: 0,
        start_time: '00:00:00',
        end_time: '00:00:00',
        period: '1'
    });

    const [playerTag, setPlayerTag] = React.useReducer((old, action) => ({ ...old, ...action }), {
        team_tag_id: 0,
        team_id: 0,
        player_id: 0,
        action_id: 0,
        action_type_id: 1,
        action_result_id: 0,
        start_time: '00:00:00',
        end_time: '00:00:00'
    });

    const [gamePlayerRefresh, setGamePlayerRefresh] = React.useState(false);
    const [cpClicked, setCpclicked] = React.useState(false);

    const offenseTeam = state.offense === 'home' ? state.homePlayers : state.awayPlayers;
    const defenseTeam = state.offense === 'away' ? state.homePlayers : state.awayPlayers;

    const offenseTeamId = state.offense === 'home' ? state.home_team_id : state.away_team_id;
    const defenseTeamId = state.offense === 'away' ? state.home_team_id : state.away_team_id;

    React.useEffect(() => {
        GameService.getAllActionResults().then((res) => {
            ALL_ACTION_RESULTS = res;
        });
    }, []);

    React.useEffect(() => {
        GameService.getGame(game_id).then((res) => {
            setState({
                game: res,
                home_team_id: res.home_team_id,
                away_team_id: res.away_team_id,
                home_team_name: res.home_team_name,
                away_team_name: res.away_team_name
            });
            if (res.video_url.includes('youtube.com') || res.video_url.includes('youtu.be')) {
                GameService.getNewStreamURL(res.video_url).then((res) => {
                    setState({ url: res.result.video_url });
                });
            } else {
                setState({ url: res.video_url });
            }
        });
    }, [game_id]);

    const GameTeamPlayer = React.useCallback(async () => {
        await GameService.getGameTeamPlayers({ game_id }).then((res) => {
            setState({
                homePlayers: res.home_team.map((p) => {
                    return { ...p, checked: true };
                }),
                awayPlayers: res.away_team.map((p) => {
                    return { ...p, checked: true };
                })
            });
        });
    }, [game_id, setState]);

    React.useEffect(() => {
        GameTeamPlayer();
    }, [count, game_id, GameTeamPlayer]);

    React.useEffect(() => {
        const timer = setTimeout(() => {
            GameTeamPlayer();
        }, 2000);
        return () => {
            clearTimeout(timer);
            setGamePlayerRefresh(false);
        };
    }, [gamePlayerRefresh, GameTeamPlayer]);

    const updateTagList = () => setTagCnt(tagCnt + 1);
    const handleDrawerOpen = () => setOpen(!open);

    const dispPlayerTags = React.useCallback(
        (id) => {
            if (!id) return;
            setState({ curTeamTagId: id });

            GameService.getAllPlayerTagsByTeamTag(id)
                .then((res) => {
                    setPlayerTagList(res);
                })
                .catch(() => {});
        },
        [setState, setPlayerTagList]
    );

    React.useEffect(() => {
        if (game_id <= 0) return;

        GameService.getAllTeamTagsByGame(game_id).then((res) => {
            setTeamTagList(res);
            if (!res.length) {
                setPlayerTagList([]);
                return;
            }
            setState({ curTeamTagId: res[0].id });
            dispPlayerTags(res[0].id);
        });
    }, [game_id, tagCnt, dispPlayerTags]);

    const changePlayRate = (flag) => {
        let newRate = flag ? playRate + 1 : playRate - 1;
        if (newRate < 0) newRate = 0;
        if (newRate > PLAYBACK_RATE.length - 1) newRate = PLAYBACK_RATE.length - 1;
        setPlayRate(newRate);
        setPlay(true);
    };

    React.useEffect(() => {
        setTeamTag({
            game_id,
            start_time: state.start_time,
            offensive_team_id: offenseTeamId,
            defensive_team_id: defenseTeamId
        });
    }, [offenseTeamId, defenseTeamId, state.start_time, game_id]);

    let i = 0;
    document.addEventListener('keydown', function (event) {
        if (i <= 1) {
            if (event.key === 'm') {
                changePlayRate(true);
                i++;
            }
            if (event.key === 'b') {
                changePlayRate(false);
                i++;
            }
        }
        return;
    });

    useHotkeys('h', () => seekTo(-1), HOTKEY_OPTION);
    useHotkeys('g', () => seekTo(-3), HOTKEY_OPTION);
    useHotkeys('shift+g', () => seekTo(-5), HOTKEY_OPTION);
    useHotkeys('alt+g', () => seekTo(-10), HOTKEY_OPTION);

    useHotkeys('j', () => seekTo(1), HOTKEY_OPTION);
    useHotkeys('k', () => seekTo(3), HOTKEY_OPTION);
    useHotkeys('shift+k', () => seekTo(5), HOTKEY_OPTION);
    useHotkeys('alt+k', () => seekTo(10), HOTKEY_OPTION);

    useHotkeys('esc', () => setModalOpen(false), HOTKEY_OPTION);

    useHotkeys('up', () => offensiveTeamClicked('home'), HOTKEY_OPTION);
    useHotkeys('down', () => offensiveTeamClicked('away'), HOTKEY_OPTION);

    useHotkeys(TAGGING.short_pass.hotkey, () => taggingButtonClicked(TAGGING.short_pass.value), HOTKEY_OPTION);
    useHotkeys(TAGGING.pass.hotkey, () => taggingButtonClicked(TAGGING.pass.value), HOTKEY_OPTION);
    useHotkeys(TAGGING.shot.hotkey, () => taggingButtonClicked(TAGGING.shot.value), HOTKEY_OPTION);
    useHotkeys(TAGGING.dribble.hotkey, () => taggingButtonClicked(TAGGING.dribble.value), HOTKEY_OPTION);
    useHotkeys(TAGGING.foul.hotkey, () => taggingButtonClicked(TAGGING.foul.value));
    useHotkeys(TAGGING.cross.hotkey, () => taggingButtonClicked(TAGGING.cross.value), HOTKEY_OPTION);
    useHotkeys('x', () => taggingButtonClicked('Others'), HOTKEY_OPTION);
    useHotkeys('z', () => taggingButtonClicked('GK'), HOTKEY_OPTION);
    useHotkeys('v', () => setClicked(true), HOTKEY_OPTION);

    useHotkeys('return', () => setPlay((v) => !v), HOTKEY_OPTION);
    useHotkeys('n', () => setPlay((v) => !v), HOTKEY_OPTION);

    const taggingButtonClicked = (action) => {
        setModalOpen(true);
        setModalContent(action);
        setTeamTagId(null);
        setTeamTagClicked(true);
        setPlay(false);

        const curTime = player.current.getCurrentTime();
        setTeamTag({ end_time: toHHMMSS(`${curTime + config.sec_after}`) });
        setPlayerTag({
            team_id: offenseTeamId,
            action_id: action.id,
            start_time: toHHMMSS(`${curTime - config.sec_before}`),
            end_time: toHHMMSS(`${curTime + config.sec_after}`)
        });
    };

    const addTeamTag = React.useCallback(
        async (isCP) => {
            try {
                const res = await GameService.addTeamTag({
                    ...teamTag,
                    end_time: isCP ? toHHMMSS(player.current.getCurrentTime()) : teamTag.end_time
                });
                setModalOpen(false);
                setTeamTag({ id: res.id });
                setTagCnt(tagCnt + 1);
                return res;
            } catch (e) {}
        },
        [teamTag, setModalOpen, setTeamTag, setTagCnt, tagCnt]
    );

    const addPlayerTag = React.useCallback(async (PTag) => {
        return await GameService.addPlayerTag(PTag);
    }, []);

    let playerTagTime = [];

    const sendTimeData = (data) => {
        playerTagTime.push(data);
        setClicked(true);
        setTeamTagClicked(true);
    };

    const setTaggingState = (tags) => {
        let start_time = '00:00:00';
        let end_time = '00:00:00';

        if (playerTagTime.length <= 0) {
            start_time = playerTag.start_time;
            end_time = playerTag.end_time;
        } else {
            start_time = playerTagTime[0].start_time;
            end_time = playerTagTime[0].end_time;
        }
        setTempPlayerTagList([
            ...temp_playerTag_list,
            ...tags.map((tag) => {
                return {
                    ...tag,
                    start_time: start_time,
                    end_time: end_time
                };
            })
        ]);
    };

    React.useEffect(() => {
        GameService.updateTaggerConfig(config).then((res) => {
            setUser({
                ...getUser(),
                user_config: {
                    sec_before: config.sec_before,
                    sec_after: config.sec_after
                }
            });
        });
    }, [config]);

    const saveTags = React.useCallback(
        async (isCP = false) => {
            setPlay(false);
            let tTag = null;
            if (teamTagId !== null) {
                tTag = teamTagId;
            } else {
                let temp = await addTeamTag(isCP);
                tTag = temp.id;
            }
            for (const pTag of temp_playerTag_list) {
                await addPlayerTag({ ...pTag, team_tag_id: tTag });
            }
            setTempPlayerTagList([]);

            dispPlayerTags(teamTagId !== null ? teamTagId : tTag);
        },
        [addTeamTag, addPlayerTag, setPlay, setTempPlayerTagList, dispPlayerTags, teamTagId, temp_playerTag_list]
    );

    React.useEffect(() => {
        const last = temp_playerTag_list.slice(-1)[0];

        if (ALL_ACTION_RESULTS.find((f) => f.id === last?.action_result_id)?.end_possession) {
            if (temp_playerTag_list.find((t) => ALL_ACTION_RESULTS.find((f) => f.id === t?.action_result_id)?.change_possession)) {
                setTeamTag({ start_time: teamTag.start_time });
            }
        }

        if (cpClicked) {
            setPlay(false);
        } else {
            setPlay(true);
        }
        setModalOpen(false);
    }, [temp_playerTag_list, cpClicked, teamTag.start_time]);

    React.useEffect(() => {
        if (clicked) {
            setCpclicked(true);
            saveTags(true);
            setClicked(false);
        }
    }, [clicked, saveTags]);

    const offensiveTeamClicked = (team) => {
        const st = toHHMMSS(`${player.current.getCurrentTime() ? player.current.getCurrentTime() : 0}`);
        setState({ offense: team, start_time: subSecToHHMMSS(st, 5) });
    };

    // function to display time difference between first tag and current tag in same period
    const displayTagInfo = React.useCallback(() => {
        if (curTeamTag === null) return '';

        const sameTags = teamTagList.filter((item) => item.period === curTeamTag.period); // get tags that period is same as current tag

        if (player && player.current.getCurrentTime() < toSecond(sameTags[sameTags.length - 1].start_time)) return '';

        const period = getPeriod(curTeamTag.period);
        let time = Math.floor(player.current.getCurrentTime()) - toSecond(sameTags[sameTags.length - 1].start_time); // Calculates the difference

        let minutes = Math.floor(time / 60);
        let seconds = time - minutes * 60;

        if (minutes < 0) minutes = '0';
        else if (minutes < 10) minutes = '0' + minutes;

        if (seconds < 0) seconds = '0';
        else if (seconds < 10) seconds = '0' + seconds;

        return `${period} ${minutes}:${seconds}`;
    }, [curTeamTag, teamTagList, player]);

    React.useEffect(() => {
        setCurTagStatusText(displayTagInfo());
    }, [state.curTeamTagId, displayTagInfo]);

    React.useEffect(() => {
        let defPlayers = [];
        defenseTeam.forEach((data) => {
            if (data.player_position_name === 'Goalkeeper') {
                defPlayers.push(data);
            }
        });
        setDefenseTeamGoalKeeper(defPlayers);
    }, [defenseTeam]);

    const [positions, setPositions] = React.useState([]);

    React.useEffect(() => {
        let flag = false;
        for (let f = 0; f < 10; f++) {
            let currentframe = videoCufr + f;
            if (newlabeldata.current[currentframe] !== undefined) {
                if (newlabeldata.current[currentframe].length < 4) continue;
                let posData = [];
                for (let i = 0; i < newlabeldata.current[currentframe].length; i++) {
                    let pos = {};
                    pos['x'] = newlabeldata.current[currentframe][i].x;
                    pos['y'] = newlabeldata.current[currentframe][i].y;
                    pos['pui'] = newlabeldata.current[currentframe][i].pui;
                    pos['w'] = newlabeldata.current[currentframe][i].w;
                    pos['h'] = newlabeldata.current[currentframe][i].h;
                    pos['id'] = newlabeldata.current[currentframe][i].id;
                    pos['jersey'] = newlabeldata.current[currentframe][i].s4u_player_jersey_number;
                    posData.push(pos);
                }
                setPositions(posData);
                flag = true;
                break;
            }
        }

        if (!flag) {
            setPositions([]);
        }
    }, [videoCufr]);

    React.useEffect(() => {
        newlabeldata.current = labeldata;
    }, [labeldata]);

    const selecthandlechange = (e) => {};

    const rightClicked = (e, playerId) => {
        e.preventDefault(); // prevent the default behaviour when right clicked
        setPlayerUniqueId(playerId);
        setPlay(false);
        setModalOpen(true);
        setIsSelected(true);
    };

    const updatelabel = (id, jersey_number) => {
        const newArr = Object.entries(newlabeldata.current).map(([key, value]) => {
            if (timeInt === '1' && key >= videoCufr) return [key, value];
            if (timeInt === '2' && key <= videoCufr) return [key, value];
            const new_value = value.map((item) => {
                item.s4u_player_jersey_number = item.pui === playerUniqueId ? jersey_number : item.s4u_player_jersey_number;
                return item;
            });
            return [key, new_value];
        });

        newlabeldata.current = Object.fromEntries(newArr);

        if (timeInt === '1') {
            gameService.updatePlayerDetection(state.game.id, playerUniqueId, id, jersey_number, -1, Math.floor(videoCufr / 30)).then((res) => {});
        }

        if (timeInt === '2') {
            gameService.updatePlayerDetection(state.game.id, playerUniqueId, id, jersey_number, Math.floor(videoCufr / 30), -1).then((res) => {});
        }

        if (timeInt === '3') {
            gameService.updatePlayerDetection(state.game.id, playerUniqueId, id, jersey_number, -1, -1).then((res) => {});
        }
    };

    React.useEffect(() => {
        if (modalOpen === false) {
            setIsSelected(false);
            setModalContent('');
        }
    }, [modalOpen]);

    return (
        <Box sx={{ display: 'flex' }}>
            <Modal disableAutoFocus open={modalOpen} onClose={() => setModalOpen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box style={style}>
                    {modalContent === TAGGING.shot.value && (
                        <Shot
                            offenseTeamId={offenseTeamId}
                            defenseTeamId={defenseTeamId}
                            offenseTeam={offenseTeam}
                            defenseTeam={defenseTeam}
                            defenseTeamGoalKeeper={defenseTeamGoalKeeper}
                            taggingState={setTaggingState}
                        />
                    )}
                    {modalContent === TAGGING.short_pass.value && <ShortPass offenseTeam={offenseTeam} taggingState={setTaggingState} offenseTeamId={offenseTeamId} />}
                    {modalContent === TAGGING.pass.value && (
                        <Pass
                            offenseTeam={offenseTeam}
                            defenseTeam={defenseTeam}
                            taggingState={setTaggingState}
                            offenseTeamId={offenseTeamId}
                            defenseTeamId={defenseTeamId}
                            defenseTeamGoalKeeper={defenseTeamGoalKeeper}
                        />
                    )}
                    {modalContent === TAGGING.cross.value && (
                        <Cross
                            offenseTeamId={offenseTeamId}
                            defenseTeamId={defenseTeamId}
                            offenseTeam={offenseTeam}
                            defenseTeam={defenseTeam}
                            taggingState={setTaggingState}
                            defenseTeamGoalKeeper={defenseTeamGoalKeeper}
                        />
                    )}
                    {modalContent === TAGGING.foul.value && (
                        <Foul offenseTeamId={offenseTeamId} defenseTeamId={defenseTeamId} offenseTeam={offenseTeam} defenseTeam={defenseTeam} taggingState={setTaggingState} />
                    )}
                    {modalContent === TAGGING.dribble.value && (
                        <Dribble
                            offenseTeamId={offenseTeamId}
                            defenseTeamId={defenseTeamId}
                            offenseTeam={offenseTeam}
                            defenseTeam={defenseTeam}
                            taggingState={setTaggingState}
                            defenseTeamGoalKeeper={defenseTeamGoalKeeper}
                        />
                    )}
                    {modalContent === 'Others' && (
                        <Others offenseTeamId={offenseTeamId} defenseTeamId={defenseTeamId} offenseTeam={offenseTeam} defenseTeam={defenseTeam} taggingState={setTaggingState} />
                    )}
                    {modalContent === 'GK' && (
                        <GK
                            offenseTeamId={offenseTeamId}
                            defenseTeamId={defenseTeamId}
                            offenseTeam={offenseTeam}
                            defenseTeam={defenseTeam}
                            taggingState={setTaggingState}
                            defenseTeamGoalKeeper={defenseTeamGoalKeeper}
                        />
                    )}
                    {modalContent === 'SELECT_PLAYER' && (
                        <SelectMainPlayers
                            key={Date.now()}
                            homeTeam={state.homePlayers}
                            awayTeam={state.awayPlayers}
                            updateGamePlayers={{ updateHomePlayers, updateAwayPlayers }}
                            game={state.game}
                            setGamePlayerRefresh={setGamePlayerRefresh}
                        />
                    )}

                    {isSelected === true && (
                        <UpdatePlayerDetection
                            homeTeam={state.homePlayers}
                            awayTeam={state.awayPlayers}
                            game={state.game}
                            setGamePlayerRefresh={setGamePlayerRefresh}
                            setModalOpen={setModalOpen}
                            updatelabel={updatelabel}
                            setTimeInt={setTimeInt}
                            timeInt={timeInt}
                            setLabelshow={setLabelshow}
                            labelshow={hideLabel[playerUniqueId]}
                        />
                    )}
                </Box>
            </Modal>
            <CssBaseline />
            <Drawer
                sx={{
                    width: '33%',
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: '33%',
                        boxSizing: 'border-box'
                    }
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <TeamTagTable
                    rows={teamTagList}
                    sx={{ height: '60%', p: 1, width: '100%' }}
                    updateTagList={updateTagList}
                    handleRowClick={(row) => {
                        setCurTeamTag(row);
                        player.current.seekTo(toSecond(row?.start_time));
                        dispPlayerTags(row?.id);
                    }}
                    selectedId={state.curTeamTagId}
                    setTeamTagClicked={setTeamTagClicked}
                    teamTagClicked={teamTagClicked}
                    setTeamTagId={setTeamTagId}
                    setStartTime={setStartTime}
                />
                <IndividualTagTable
                    sx={{ height: '40%', p: 1, width: '100%' }}
                    rows={playerTagList}
                    offenseTeamId={offenseTeamId}
                    offenseTeam={offenseTeam}
                    defenseTeam={defenseTeam}
                    updateTagList={() => {
                        dispPlayerTags(state.curTeamTagId);
                    }}
                    taggingState={setTaggingState}
                    setPlay={setPlay}
                    sendTimeData={sendTimeData}
                    teamTagClicked={teamTagClicked}
                    startTime={startTime}
                />
            </Drawer>

            <Main open={open}>
                <div style={{ width: 30 }}>
                    <Tooltip title={`${open ? 'Close' : 'Open'} Tags`}>
                        <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerOpen} edge="start" sx={{ height: 50, width: 50, position: 'fixed', zIndex: 1300, top: '45%' }}>
                            {open ? <KeyboardArrowLeftIcon /> : <KeyboardArrowRightIcon />}
                        </IconButton>
                    </Tooltip>
                </div>
                <Box>
                    <div style={{ maxWidth: '86%', margin: 'auto', position: 'relative' }}>
                        <div className="player-wrapper">
                            <div className="detection" ref={overlayElRef}>
                                {isShow && labelType === 'first' && <LabelView positions={positions} width={width} height={height} type={1} rightClicked={rightClicked} hideLabel={hideLabel} />}
                                {isShow && labelType === 'second' && <LabelViewCircle positions={positions} width={width} height={height} type={1} rightClicked={rightClicked} hideLabel={hideLabel} />}
                                {isShow && labelType === 'third' && <LabelViewCircle positions={positions} width={width} height={height} type={2} rightClicked={rightClicked} hideLabel={hideLabel} />}
                                {isShow && labelType === 'fourth' && <LabelView positions={positions} width={width} height={height} type={2} rightClicked={rightClicked} hideLabel={hideLabel} />}
                            </div>

                            <ReactPlayer
                                className="react-player"
                                url={state.url}
                                // url={video}

                                ref={player}
                                onPlay={() => setPlay(true)}
                                onPause={() => setPlay(false)}
                                // onDuration ={(duration) => {
                                //     let videoDr = duration
                                // }}
                                // onProgress={(progress) =>{
                                //     if (videoDr>0){
                                //         let videoEls = progress.played * videoDr
                                //     }
                                // }}
                                playing={play}
                                playbackRate={PLAYBACK_RATE[playRate].rate}
                                controls={true}
                                width="97%"
                                height="97%"
                                style={{
                                    pointerEvents: 'auto'
                                }}
                            />

                            <div
                                style={{
                                    width: '100%',
                                    position: 'absolute',
                                    top: 0,
                                    left: 15,
                                    display: 'flex'
                                }}
                            >
                                <div className="fnbuttons" onClick={fnShow}>
                                    {isShow ? <img src={hideImg} style={{ filter: 'invert(1)' }} alt="Hide" /> : <img src={showImg} style={{ filter: 'invert(1)' }} alt="Show" />}
                                </div>
                                {isShow && (
                                    <div style={{ marginLeft: 10 }}>
                                        <select name="cars" defaultValue={labelType} onChange={selecthandlechange}>
                                            <option value="first"> 1 </option>
                                            <option value="second"> 2 </option>
                                            <option value="third"> 3 </option>
                                            <option value="fourth"> 4 </option>
                                        </select>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {open && (
                        <>
                            <Box sx={{ flexGrow: 1, textAlign: 'center', marginTop: '-20px' }}>
                                <IconButton
                                    sx={{ my: 1 }}
                                    onClick={() => {
                                        setCount(count + 1);
                                    }}
                                >
                                    <RefreshIcon />
                                </IconButton>
                                {[-10, -5, -3, -1].map((t) => (
                                    <ControlButton
                                        key={t}
                                        onClick={() => {
                                            seekTo(t);
                                        }}
                                    >
                                        {t}s
                                    </ControlButton>
                                ))}

                                <ControlButton onClick={() => changePlayRate(false)}>slow</ControlButton>

                                {play ? (
                                    <ControlButton style={{ width: 100 }} startIcon={<PauseCircleOutlineIcon />} onClick={() => setPlay(false)}>
                                        Pause
                                    </ControlButton>
                                ) : (
                                    <ControlButton
                                        style={{ width: 100 }}
                                        startIcon={<PlayCircleOutlineIcon />}
                                        onClick={() => {
                                            setPlay(true);
                                            setCpclicked(false);
                                        }}
                                    >
                                        Play
                                    </ControlButton>
                                )}

                                <label style={{ width: '40px' }}>{PLAYBACK_RATE[playRate].label}</label>

                                <ControlButton onClick={() => changePlayRate(true)}>fast</ControlButton>

                                {[1, 3, 5, 10].map((t) => (
                                    <ControlButton key={t} onClick={() => seekTo(t)}>
                                        {t}s
                                    </ControlButton>
                                ))}
                            </Box>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <Box sx={{ mx: 1, textAlign: 'center' }}>
                                    <TextField
                                        label="sec. before"
                                        sx={{ m: 1, width: 90 }}
                                        inputProps={{ min: 0, style: { textAlign: 'center' } }}
                                        type="number"
                                        value={config.sec_before}
                                        onChange={(e) => setConfig({ sec_before: e.target.value })}
                                    />
                                    <TextField
                                        label="sec. after"
                                        sx={{ m: 1, width: 90 }}
                                        inputProps={{ min: 0, style: { textAlign: 'center' } }}
                                        type="number"
                                        value={config.sec_after}
                                        onChange={(e) => setConfig({ sec_after: e.target.value })}
                                    />
                                    <div>
                                        <Button variant="outlined" onClick={(e) => taggingButtonClicked('SELECT_PLAYER')}>
                                            Select Players
                                        </Button>
                                    </div>
                                </Box>
                                <Box sx={{ textAlign: 'center', mt: 1 }}>
                                    {['home', 'away'].map((t) => (
                                        <ControlButton
                                            key={t}
                                            fullWidth
                                            style={{ backgroundColor: t === state.offense && 'darkblue', color: t === state.offense && '#ffffff' }}
                                            onClick={() => {
                                                offensiveTeamClicked(t);
                                                setPlay(true);
                                                setCpclicked(false);
                                            }}
                                        >
                                            {state[`${t}_team_name`]}
                                        </ControlButton>
                                    ))}
                                    <Box style={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-around', marginLeft: 20 }}>
                                        Start Time : {state.start_time}
                                        <ControlButton
                                            sx={{ mr: 0 }}
                                            onClick={() => {
                                                saveTags(true);
                                                setCpclicked(true);
                                            }}
                                        >
                                            C.P. (v)
                                        </ControlButton>
                                    </Box>
                                </Box>

                                <Grid container spacing={0.5} sx={{ textAlign: 'center', mt: 1, mx: 2, maxWidth: 220 }}>
                                    {Object.keys(TAGGING).map((key, i) => (
                                        <Grid key={i} item xs={6} onClick={() => taggingButtonClicked(TAGGING[key].value)}>
                                            <TagButton style={{ textTransform: 'none', fontSize: '10px' }}>
                                                {TAGGING[key].value} ({TAGGING[key].hotkey})
                                            </TagButton>
                                        </Grid>
                                    ))}
                                </Grid>

                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <TagButton onClick={(e) => taggingButtonClicked('Others')} style={{ textTransform: 'none', fontSize: '10px', maxWidth: 10, height: 65, marginTop: '12px' }}>
                                        Others (x)
                                    </TagButton>

                                    <TagButton onClick={(e) => taggingButtonClicked('GK')} style={{ textTransform: 'none', fontSize: '10px', maxWidth: 10, height: 48, marginTop: '12px' }}>
                                        GK (z)
                                    </TagButton>
                                </div>

                                <RadioGroup
                                    sx={{ my: 0, mx: 2 }}
                                    aria-label="firstsecond"
                                    name="row-radio-buttons-group"
                                    value={teamTag.period}
                                    onChange={(e) => setTeamTag({ period: e.target.value })}
                                >
                                    <FormControlLabel value="1" control={<Radio />} label="1st half" />
                                    <FormControlLabel value="2" control={<Radio />} label="2nd half" />
                                    <FormControlLabel value="3" control={<Radio />} label="Overtime" />
                                </RadioGroup>
                            </div>
                        </>
                    )}
                </Box>
            </Main>
        </Box>
    );
}
