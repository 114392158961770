import React, { useState, useEffect, useReducer } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import GameService from '../../../services/game.service';
import Upload from '../../../common/upload';
import { PLAYER_ICON_DEFAULT } from '../../../common/staticData';

const init_player = {
    player_id: 0,
    player_first_name: '',
    player_last_name: '',
    player_full_name: '',
    player_date_of_birth: new Date(),
    player_image: '',
    player_weight: 60,
    player_height: 170,
    player_strong_foot: '',
    player_age_group_id: 0,
    player_age_group_name: '',
    player_jersey_number: 0,
    player_position_id: 0,
    player_position_name: '',
    player_second_position_id: 0,
    player_second_position_name: '',
    player_position_role_one_id: '43',
    player_position_role_two_id: '43',
    player_position_role_one_name: 'Unknown',
    player_position_role_two_name: 'Unknown'
};

const init_error = {
    player_first_name: false,
    player_last_name: false,
    player_position_id: false,
    player_jersey_number: false
};

const weightOptions = Array.from({ length: 51 }, (_, index) => 50 + index);
const heightOptions = Array.from({ length: 56 }, (_, index) => 150 + index);
const footOptions = ['Left', 'Right', 'Both'];


export default function PlayerFormDialog({ open, onResult, edit = null, t, positions, ageGroups, roles }) {
    const [roleOneList, setRoleOneList] = useState([]);
    const [roleTwoList, setRoleTwoList] = useState([]);
    const [initPlayerData, setInitPlayerData] = useState(init_player);

    const setPositionRole = (pos_id) => {
        if (pos_id) {
            setRoleOneList(roles.filter((role) => (role.visible && role.position_id === pos_id) || role.id === '43'));
        } else setRoleOneList(roles.filter((role) => role.id === '43'));
        let pos_name = '';
        positions &&
            positions.map((pos) => {
                if (pos.id === pos_id) pos_name = pos.name;
            });
        setPlayerData({ player_position_name: pos_name, player_position_role_one_id: '43' });
    };

    const setSecondPositionRole = (pos_id) => {
        if (pos_id) {
            setRoleTwoList(roles.filter((role) => (role.visible && role.position_id === pos_id) || role.id === '43'));
        } else setRoleTwoList(roles.filter((role) => role.id === '43'));
        let pos_name = '';
        positions &&
            positions.map((pos) => {
                if (pos.id === pos_id) pos_name = pos.name;
            });
        setPlayerData({ player_second_position_name: pos_name, player_position_role_two_id: '43' });
    };

    const [playerData, setPlayerData] = useReducer((old, action) => {
        if (action.hasOwnProperty('player_age_group_id') && action.player_age_group_id !== old.player_age_group_id) {
            let ageGroupName = '';
            ageGroups &&
                ageGroups.map((age) => {
                    if (age.id === action.player_age_group_id) ageGroupName = age.name;
                });
            action.player_age_group_name = ageGroupName;
        }
        if (action.hasOwnProperty('player_position_role_one_id') && action.player_position_role_one_id !== old.player_position_role_one_id) {
            let role_one_name = '';
            roles &&
                roles.map((role) => {
                    if (role.id === action.player_position_role_one_id) role_one_name = role.role;
                });
            action.player_position_role_one_name = role_one_name;
        }
        if (action.hasOwnProperty('player_position_role_two_id') && action.player_position_role_two_id !== old.player_position_role_two_id) {
            let role_two_name = '';
            roles &&
                roles.map((role) => {
                    if (role.id === action.player_position_role_two_id) role_two_name = role.role;
                });
            action.player_position_role_two_name = role_two_name;
        }
        const newState = { ...old, ...action };
        return newState;
    }, init_player);

    const [error, setError] = useReducer((old, action) => ({ ...old, ...action }), init_error);

    useEffect(() => {
        setError({
            player_first_name: playerData.player_first_name.length === 0,
            player_last_name: playerData.player_last_name.length === 0,
            player_position_id: !playerData.player_position_id,
            player_jersey_number: Number(playerData.player_jersey_number) < 0
        });
    }, [playerData]);

    useEffect(() => {
        setError(init_error);
        if (edit !== null) {
            const data = {
                player_id: edit?.player_id,
                player_first_name: edit?.player_first_name.trim(),
                player_last_name: edit?.player_last_name.trim(),
                player_full_name: edit?.player_first_name.trim() + ' ' + edit?.player_last_name.trim(),
                player_date_of_birth: edit?.player_date_of_birth ?? new Date(),
                player_image: edit?.player_image,
                player_weight: edit?.player_weight,
                player_height: edit?.player_height,
                player_strong_foot: edit?.player_strong_foot,
                player_age_group_id: edit?.player_age_group_id,
                player_age_group_name: edit?.player_age_group_name,
                player_jersey_number: edit?.player_jersey_number,
                player_position_id: edit?.player_position_id,
                player_position_name: edit?.player_position_name,
                player_second_position_id: edit?.player_second_position_id,
                player_second_position_name: edit?.player_second_position_name,
                player_position_role_one_id: edit?.player_position_role_one_id ?? '43',
                player_position_role_two_id: edit?.player_position_role_two_id ?? '43',
                player_position_role_one_name: edit?.player_position_role_one_name ?? 'Unknown',
                player_position_role_two_name: edit?.player_position_role_two_name ?? 'Unknown'
            };
            setPlayerData(data);
            setInitPlayerData(data);
            setPositionRole(data.player_position_id);
            setSecondPositionRole(data.player_second_position_id);
            setTimeout(() => {
                setPlayerData({ player_position_role_one_id: data.player_position_role_one_id, player_position_role_two_id: data.player_position_role_two_id });
            }, 300);
        } else setPlayerData(init_player);
    }, [edit]);

    const checkErrorPlayer = () => {
        return !Object.keys(error).find((key) => error[key]);
    };

    const handlePlayerClose = (result) => {
        setError(init_error);
        if (result) {
            if (!playerData.player_id) {
                GameService.addPlayer({ ...playerData }).then((res) => {
                    if (res.status === 'success') {
                        const msg = `Player data is successfully added!`;
                        onResult({ open: false, msg, result: 'success', data: { ...playerData, player_id: res.data.id }, type: 'create' });
                    } else {
                        onResult({ open: false, msg: res.data, result: 'error' });
                    }
                });
            } else {
                GameService.updatePlayer({ ...playerData })
                    .then((res) => {
                        const msg = `Player data is successfully updated!`;
                        onResult({ open: false, msg: res, result: 'success', data: playerData, type: 'update' });
                    })
                    .catch((e) => {
                        onResult({ open: false, result: 'close' });
                    });
            }
        } else {
            setPlayerData(initPlayerData);
            onResult({ open: false, result: 'close' });
        }
    };

    return (
        <Dialog open={open} onClose={(e) => handlePlayerClose(false)} maxWidth="lg">
            <DialogTitle>
                {!edit ? t('Add') : t('Edit')} {t('Player')}
            </DialogTitle>
            <DialogContent style={{ display: 'flex', gap: '8px' }}>
                <Upload
                    dirName={process.env.REACT_APP_DIR_PLAYER}
                    img={playerData.player_image ? (playerData.player_image.includes(process.env.REACT_APP_S3_URI) ? playerData.player_image : process.env.REACT_APP_S3_URI + playerData.player_image) : PLAYER_ICON_DEFAULT}
                    onURL={(url) => setPlayerData({ player_image: url })}
                    fimeName={''}
                    btn_name={t('Upload')}
                />
                <Box className="d-flex flex-column">
                    <Box className="d-flex">
                        <TextField
                            autoFocus
                            sx={{ m: 0.8 }}
                            value={playerData.player_first_name}
                            onChange={(e) => setPlayerData({ player_first_name: e.target.value, player_full_name: `${e.target.value} ${playerData.player_last_name}` })}
                            helperText={error.player_first_name ? 'First Name cannot be empty' : ''}
                            error={error.player_first_name}
                            label={t('FirstName')}
                            fullWidth
                            variant="outlined"
                        />
                        <TextField
                            sx={{ m: 0.8 }}
                            value={playerData.player_last_name}
                            onChange={(e) => setPlayerData({ player_last_name: e.target.value, player_full_name: `${playerData.player_first_name} ${e.target.value}` })}
                            helperText={error.player_last_name ? 'Last Name cannot be empty' : ''}
                            error={error.player_last_name}
                            label={t('LastName')}
                            fullWidth
                            variant="outlined"
                        />
                    </Box>

                    <Box className="d-flex">
                        <TextField
                            sx={{ m: 0.8 }}
                            label={`${t('Jersey')} ${t('Number')}`}
                            type="number"
                            fullWidth
                            value={playerData.player_jersey_number}
                            onChange={(e) => setPlayerData({ player_jersey_number: e.target.value })}
                            helperText={error.player_jersey_number ? 'Jersey Number cannot be less than 0' : ''}
                            error={error.player_jersey_number}
                            variant="outlined"
                        />
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label={t('DOB')}
                                value={playerData.player_date_of_birth}
                                onChange={(newValue) => {
                                    setPlayerData({ player_date_of_birth: newValue });
                                }}
                                renderInput={(params) => <TextField sx={{ m: 0.8, minWidth: '31%' }} {...params} />}
                            />
                        </LocalizationProvider>
                        <FormControl fullWidth sx={{ m: 0.8 }}>
                            <InputLabel id="age_group">{t('Age Group')}</InputLabel>
                            <Select
                                labelId="age_group"
                                id="age_group_id"
                                value={playerData.player_age_group_id}
                                label="Age Group"
                                onChange={(e) => setPlayerData({ player_age_group_id: e.target.value })}
                            >
                                {ageGroups &&
                                    ageGroups.map((item, index) => (
                                        <MenuItem key={index} value={item.id}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box className="d-flex">
                        <FormControl fullWidth sx={{ m: 0.8 }}>
                            <InputLabel id="height_lbl">{t('Height')}</InputLabel>
                            <Select labelId="height" id="height" value={playerData.player_height} label="Height" onChange={(e) => setPlayerData({ player_height: e.target.value })}>
                                {heightOptions.map((item, index) => (
                                    <MenuItem key={index} value={item}>
                                        {item} cm
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth sx={{ m: 0.8 }}>
                            <InputLabel id="weight_lbl">{t('Weight')}</InputLabel>
                            <Select labelId="weight" id="weight" value={playerData.player_weight} label="Weight" onChange={(e) => setPlayerData({ player_weight: e.target.value })}>
                                {weightOptions.map((item, index) => (
                                    <MenuItem key={index} value={item}>
                                        {item} Kg
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl fullWidth sx={{ m: 0.8 }}>
                            <InputLabel id="strong_foot_lbl">{t('Preferred Foot')}</InputLabel>
                            <Select
                                labelId="strong_foot"
                                id="strong_foot"
                                value={playerData.player_strong_foot}
                                label="Preferred Foot"
                                onChange={(e) => setPlayerData({ player_strong_foot: e.target.value })}
                            >
                                {footOptions.map((item, index) => (
                                    <MenuItem key={index} value={item}>
                                        {item}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box className="d-flex">
                        <FormControl fullWidth sx={{ m: 0.8 }}>
                            <InputLabel id="position_lbl">{t('Position')}</InputLabel>
                            <Select
                                labelId="position"
                                id="position"
                                value={playerData.player_position_id}
                                label="Position"
                                onChange={(e) => {
                                    setPositionRole(e.target.value);
                                    setPlayerData({ player_position_id: e.target.value });
                                }}
                            >
                                {positions &&
                                    positions.map((item, index) => (
                                        <MenuItem key={index} value={item.id}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth sx={{ m: 0.8 }}>
                            <InputLabel id="position_role_one_lbl">{t('Position Role')}</InputLabel>
                            <Select
                                labelId="position_role_one"
                                id="position_role_one"
                                value={playerData.player_position_role_one_id}
                                label="Position Role"
                                onChange={(e) => setPlayerData({ player_position_role_one_id: e.target.value })}
                            >
                                {roleOneList &&
                                    roleOneList.map((item, index) => (
                                        <MenuItem key={index} value={item.id}>
                                            {item.role}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Box className="d-flex">
                        <FormControl fullWidth sx={{ m: 0.8 }}>
                            <InputLabel id="position_lbl">{t('Second Position')}</InputLabel>
                            <Select
                                labelId="second_position"
                                id="second_position"
                                value={playerData.player_second_position_id}
                                label="Second Position"
                                onChange={(e) => {
                                    setSecondPositionRole(e.target.value);
                                    setPlayerData({ player_second_position_id: e.target.value });
                                }}
                            >
                                {positions &&
                                    positions.map((item, index) => (
                                        <MenuItem key={index} value={item.id}>
                                            {item.name}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth sx={{ m: 0.8 }}>
                            <InputLabel id="position_role_two_lbl">{t('Second Position Role')}</InputLabel>
                            <Select
                                labelId="position_role_two"
                                id="position_role_two"
                                value={playerData.player_position_role_two_id}
                                label="Second Position Role"
                                onChange={(e) => setPlayerData({ player_position_role_two_id: e.target.value })}
                            >
                                {roleTwoList &&
                                    roleTwoList.map((item, index) => (
                                        <MenuItem key={index} value={item.id}>
                                            {item.role}
                                        </MenuItem>
                                    ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={(e) => handlePlayerClose(false)}>{t('Cancel')}</Button>
                <Button onClick={(e) => checkErrorPlayer() && handlePlayerClose(true)}>{t('Done')}</Button>
            </DialogActions>
        </Dialog>
    );
}
